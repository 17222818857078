<template>
	<div class="my-ports relative-container">
		<Nav />
		<span class="overlay" :class="{ active: isMenuOpen || isPortSearchModalOpen || isAccessModalOpen }"></span>
		<div class="header header-standard">
			<HeaderNavBar back add />
		</div><!-- header -->
		<div class="header-image blue"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="StarIcon"
				title="My Ports"
				colour="blue"
			/>
			<div class="section-gap section-port-list">
				<div class="section-title-wrap">
					<h2 class="section-title">{{ count }} Port{{ count > 1 ? 's' : '' }}</h2>
				</div>
				<div v-for="(values, continent) in ports" :key="continent" class="port-list-wrap gap">
					<span class="post-list-sub">{{ continent }}</span>
                    <PortListBlock
                        v-for="port in values"
                        :key="port.id"
                    	:port="port.name"
                    	:country="port.country"
                        :countryCode="port.country_code"
                        :id="port.id"
                    />
				</div><!-- port list wrap -->
			</div>
			<div class="section-gap-big section-ports">
				<div class="field field-icon field-icon-left">
					<div class="control">
                        <input class="search-field" placeholder="Find more Ports" type="text" @click="openSearchModal">
						<SearchIcon style="width: 20px; height: 20px; top: 15px;" class="icon icon-search icon-stroke-3" />
					</div>
				</div>
			</div>
		</div>
		<Menu />
        <PortSearchOverlay />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Menu from '@/components/Menu.vue';
	import SearchIcon from '@/assets/search.svg';
    import PortSearchOverlay from '@/components/PortSearchOverlay.vue';
	import PortListBlock from '@/components/PortListBlock.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
    import UserService from '@/services/UserService';
	export default {
		name: 'MyPorts',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
			SearchIcon,
			PortListBlock,
			SectionTitle,
            PortSearchOverlay
		},
		data () {
	        return {
                count: 0,
                ports: []
	        };
	    },
	    methods: {
            getPorts () {
                return UserService.getAllPorts().then((response) => {
                    this.count = response.data.count;
                    this.ports = response.data.ports;
                });
            },
            openSearchModal () {
                this.$store.commit('PORT_SEARCH_TOGGLE');
            }
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        },
            isPortSearchModalOpen () {
                return this.$store.state.isPortSearchModalOpen;
            },
            isAccessModalOpen () {
                return this.$store.state.isAccessModalOpen;
            }
		},
		mounted () {
            this.getPorts();
        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
    .search-field {
        padding: 0 50px !important;
    }
</style>
